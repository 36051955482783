import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router";
import { useDispatch } from "react-redux";

export const Home = lazy(() => import("../Pages/Home/Home.tsx"));

export const Kundali = lazy(() => import("../Pages/Kundali.tsx"));
export const Festivals = lazy(() => import("../Pages/Festivals.tsx"));
export const Tarot = lazy(() => import("../Pages/Tarot/Predication.tsx"));

export const PrivacyPolicy = lazy(
  () => import("../Pages/Polices/PrivacyPolicy.tsx")
);
export const Terms = lazy(() => import("../Pages/Polices/Terms.tsx"));
export const RefundPolicy = lazy(() => import("../Pages/Polices/Refund.tsx"));
export const Blogs = lazy(() => import("../Pages/Blogs/Blog.tsx"));
export const BlogDetail = lazy(() => import("../Pages/Blogs/BlogDetail.tsx"));
export const OrderConfirmPage = lazy(
  () => import("../Pages/OrderConfirmPage.tsx")
);
export const Contactus = lazy(
  () => import("../Pages/Contact/Contact.tsx")
);

// admin panel.

export const AdminHome = lazy(() => import("../Pages/Admin/Home/Home.jsx"));
export const AdminBlogEdit = lazy(
  () => import("../Pages/Admin/dashboard/BlogEdit.tsx")
);

export const AdminBlogInsertion = lazy(
  () => import("../Pages/Admin/dashboard/BlogInsertion.jsx")
);

export const AdminBlogs = lazy(
  () => import("../Pages/Admin/dashboard/Blogs.jsx")
);

export const Dashboard = lazy(
  () => import("../Pages/Admin/dashboard/Dashboard.jsx")
);
export const UserKundali = lazy(
  () => import("../Pages/Admin/dashboard/UserKundali.jsx")
);
