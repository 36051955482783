import React, { useState } from "react";

import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { TbWorld } from "react-icons/tb";
import "./Blog.css";
import axios from "axios";
import { formats, modules } from "../../../Data/DataArray.ts";

function BlogInsertion() {
  const [blog_title, setBlog_title] = useState("");
  const [blog_image, setBlog_image] = useState(null);
  const [blogContent, setBlogContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState({
    image:
      "https://images.pexels.com/photos/3408744/pexels-photo-3408744.jpeg?auto=compress&cs=tinysrgb&w=600",
    filename: "preview.jpg",
  });

  const _handleBlogSubmit = async () => {
    const formdata = new FormData();
    setLoading(true);
    formdata.append("blog_title", blog_title);
    formdata.append("blog_content", blogContent);
    formdata.append("blog_image", blog_image);

    const request = await axios
      .post("https://www.astrogyanam.com/Astro_api/Blogs/Blogs.php", formdata)
      .then(() => {
        window.location.assign("/in/hq/Admin/dashboard/blogs");
      });
    return request;
  };

  const _handleContentPreview = (e) => {
    let contentDiv = document.getElementsByClassName("content")[0];
    contentDiv.innerHTML = e;
    setBlogContent(e);
  };
  return (
    <div className="w-full flex items-start bg-black pb-10">
      <div className="w-1/2 bg-black border-r border-r-white/5 min-h-screen   right-0 top-0 bottom-0">
        <div className="py-4 w-full px-8 border-b border-b-white/5 flex items-center justify-between">
          <div className="flex items-center text-white text-sm font-bold">
            <span>Blog</span>
          </div>
          <div className="flex items-center text-white text-sm font-bold">
            {loading ? (
              <div className="py-1 px-5 text-black rounded bg-white">
                Posting
              </div>
            ) : (
              <div
                className="py-1 px-5 text-black rounded bg-white"
                onClick={_handleBlogSubmit}
              >
                <div>save</div>
              </div>
            )}
          </div>
        </div>
        <div className="py-9 px-8 text-white/80 font-semibold text-sm">
          <div className="w-full mb-3">
            <div className="font-bold">Title</div>
            <div className="w-full text-base h-12 py-1 pl-4 rounded bg-[#333333] mt-2">
              <input
                type=""
                value={blog_title}
                onChange={(e) => {
                  setBlog_title(e.target.value);
                }}
                className="w-full h-full outline-none border-none bg-transparent"
              />
            </div>
          </div>
          <div className="w-full mb-3">
            <div className="font-bold">Slug</div>
            <div className="text-base w-full h-12 py-1 pl-4 rounded bg-[#333333] mt-2 flex items-center">
              {blog_title.split(" ").join("-")}
            </div>
            <div className="text-white/70 my-1 flex items-center text-xs font-thin">
              <TbWorld />
              <span className="ml-1">
                astrogyanam.com/blog/{blog_title.split(" ").join("-")}
              </span>
            </div>
          </div>
          <div className="w-full mb-3">
            <div className="font-bold">cover image</div>
            <div className="text-base w-full h-16 p-1 rounded mt-2 flex items-center">
              <div className="w-16 h-full bg-[#333333] rounded">
                <img
                  src={previewImage.image}
                  className="w-full h-full rounded"
                  alt="cover_image"
                />
              </div>
              <div className="mx-3 flex items-start flex-col">
                <div className="text-xl capitalize">
                  {previewImage.filename}
                </div>
                <div className="bg-[#4ade80] w-32 mt-1 rounded px-3 py-2 text-black text-center text-xs relative">
                  <input
                    type="file"
                    name=""
                    id=""
                    className="w-full h-full opacity-0 absolute"
                    onChange={(e) => {
                      const blobURL = URL.createObjectURL(e.target.files[0]);
                      setBlog_image(e.target.files[0]);
                      setPreviewImage({
                        filename: "preview_image",
                        image: blobURL,
                      });
                    }}
                  />
                  update
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10">
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="write your content ...."
              onChange={_handleContentPreview}
              style={{ height: "220px", border: "none" }}
            ></ReactQuill>
          </div>
        </div>
      </div>
      <div className="w-1/2 min-h-screen text-white/90 text-sm">
        <div className="content"></div>
      </div>
    </div>
  );
}

export default BlogInsertion;
