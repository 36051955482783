export const zodiacSign = [
  "Aries",
  "Taurus",
  "Gemini",
  "Cancer",
  "Leo",
  "Virgo",
  "Libra",
  "Scorpio",
  "Sagittarius",
  "Capricorn",
  "Aquarius",
  "Pisces",
];

export const modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};

export const formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "link",
  "image",
  "align",
  "size",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const Festivalsarray = [
  {
    month: "January",
    Festival: [
      { date: 7, festivals: ["Saphala Ekadashi"] },
      { date: 9, festivals: ["Masik Shivaratri", "Pradosh Vrat (K)"] },
      { date: 11, festivals: ["Paush Amavasya"] },
      { date: 15, festivals: ["Pongal", "Uttarayan", "Makar Sankranti"] },
      { date: 21, festivals: ["Pausha Putrada Ekadashi"] },
      { date: 23, festivals: ["Pradosh Vrat (S)"] },
      { date: 25, festivals: ["Paush Purnima Vrat"] },
      { date: 29, festivals: ["Sankashti Chaturthi"] },
    ],
  },
  {
    month: "february",
    Festival: [
      { date: 6, festivals: ["Shattila Ekadashi "] },
      { date: 7, festivals: ["Pradosh Vrat (K)"] },
      { date: 8, festivals: ["Masik Shivaratri "] },
      { date: 9, festivals: ["Magha Amavasya"] },
      { date: 13, festivals: ["Kumbha Sankranti "] },
      { date: 14, festivals: ["Basant Panchmi, Saraswati Puja"] },
      { date: 20, festivals: ["Jaya/Bhumi Ekadashi "] },
      { date: 21, festivals: ["Pradosh Vrat (S) "] },
      { date: 24, festivals: ["Magha Purnima Vrat "] },
      { date: 28, festivals: ["Sankashti Chaturthi "] },
    ],
  },
  {
    month: "march",
    Festival: [
      { date: 6, festivals: ["Vijaya Ekadashi "] },
      {
        date: 8,
        festivals: ["Mahashivratri, Pradosh Vrat (K), Masik Shivaratri "],
      },
      { date: 10, festivals: ["Phalguna Amavasya "] },
      { date: 14, festivals: ["Meena Sankranti"] },
      { date: 20, festivals: ["Amalaki Ekadashi "] },
      { date: 22, festivals: ["Pradosh Vrat (S)"] },
      { date: 24, festivals: ["Holika Dahan"] },
      { date: 25, festivals: ["Holi, Phalguna Purnima Vrat"] },
      { date: 28, festivals: ["Sankashti Chaturthi"] },
    ],
  },
  {
    month: "April",
    Festival: [
      { date: 5, festivals: ["Pampochani Ekadashi "] },
      { date: 6, festivals: [" Pradosh Vrat (K)"] },
      { date: 7, festivals: ["Masik Shivaratri"] },
      { date: 8, festivals: ["Chaitra Amavasya "] },
      {
        date: 9,
        festivals: ["Chaitra Navratri, Ugadi,Ghatasthapana, Gudi Padwa"],
      },
      { date: 10, festivals: ["Cheti Chand"] },
      { date: 13, festivals: ["Mesha Sankranti"] },
      { date: 17, festivals: ["Chaitra Navratri Parana, Ram Navami"] },
      { date: 19, festivals: ["Kamada Ekadashi"] },
      { date: 21, festivals: ["Pradosh Vrat (S) "] },
      { date: 23, festivals: ["Hanuman jayanti, Chaitra Purnima Vrat"] },
      { date: 27, festivals: ["Sankashti Chaturthi "] },
    ],
  },
  {
    month: "May",
    Festival: [
      { date: 4, festivals: ["Varuthini Ekadashi "] },
      { date: 5, festivals: [" Pradosh Vrat (K)"] },
      { date: 6, festivals: ["Masik Shivaratri"] },
      { date: 8, festivals: ["Vaishakha Amavasya"] },
      { date: 10, festivals: ["Akshaya Tritiya"] },
      { date: 14, festivals: ["Vrishabha Sankranti"] },
      { date: 19, festivals: ["Mohini Ekadashi"] },
      { date: 20, festivals: ["Pradosh Vrat (S)"] },
      { date: 23, festivals: ["Vaishakha Purnima Vrat "] },
      { date: 26, festivals: ["Sankashti Chaturthi"] },
    ],
  },
  {
    month: "June",
    Festival: [
      { date: 2, festivals: ["Apara Ekadashi"] },
      { date: 4, festivals: ["Masik Shivaratri, Pradosh Vrat (K)"] },
      { date: 6, festivals: ["Jyeshtha Amavasya"] },
      { date: 15, festivals: ["Mithuna Sankranti"] },
      { date: 18, festivals: ["Nirjala Ekadashi "] },
      { date: 19, festivals: ["Pradosh Vrat (S)"] },
      { date: 22, festivals: ["Jyeshtha Purnima Vrat"] },
      { date: 25, festivals: ["Sankashti Chaturthi "] },
    ],
  },
  {
    month: "July",
    Festival: [
      { date: 2, festivals: ["Yogini Ekadashi"] },
      { date: 3, festivals: ["Pradosh Vrat (K)"] },
      { date: 4, festivals: ["Masik Shivaratri"] },
      { date: 5, festivals: ["Ashadha Amavasya"] },
      { date: 7, festivals: ["Jagannath Rath Yatra"] },
      { date: 16, festivals: ["Karka Sankranti"] },
      { date: 17, festivals: ["Deva Shayani Ekadashi, Ashadhi Ekadashi"] },
      { date: 18, festivals: ["Pradosh Vrat (S)"] },
      { date: 21, festivals: ["Guru Purnima, Ashadha Purnima Vrat "] },
      { date: 24, festivals: ["Sankashti Chaturthi"] },
      { date: 31, festivals: ["Kamika Ekadashi "] },
    ],
  },
  {
    month: "August",
    Festival: [
      { date: 1, festivals: ["Pradosh Vrat (K)"] },
      { date: 2, festivals: ["Masik Shivaratri"] },
      { date: 4, festivals: ["Shravana Amavasya"] },
      { date: 7, festivals: ["Hariyali Teej"] },
      { date: 9, festivals: ["Nag Panchami"] },
      { date: 16, festivals: ["Shravana Putrada Ekadashi, Simha Sankranti"] },
      { date: 17, festivals: ["Pradosh Vrat (S)"] },
      { date: 19, festivals: ["Raksha Bandhan, Shravana Purnima Vrat"] },
      { date: 22, festivals: ["Sankashti Chaturthi, Kajari Teej"] },
      { date: 26, festivals: ["Janmashtami"] },
      { date: 29, festivals: ["Aja Ekadashi"] },
      { date: 31, festivals: ["Pradosh Vrat (K)"] },
    ],
  },
  {
    month: "September",
    Festival: [
      { date: 1, festivals: ["Masik Shivaratri"] },
      { date: 2, festivals: ["Bhadrapada Amavasya"] },
      { date: 6, festivals: ["Hartalika Teej"] },
      { date: 7, festivals: ["Ganesh Chaturthi"] },
      { date: 14, festivals: ["Parivartini Ekadashi"] },
      { date: 15, festivals: ["Pradosh Vrat (S), Onam/Thiruvonam"] },
      { date: 16, festivals: ["Kanya Sankranti"] },
      { date: 17, festivals: ["Anant Chaturdashi"] },
      { date: 18, festivals: ["Bhadrapada Purnima Vrat"] },
      { date: 21, festivals: ["Sankashti Chaturthi"] },
      { date: 28, festivals: ["Indira Ekadashi"] },
      { date: 29, festivals: ["Pradosh Vrat (K)"] },
      { date: 30, festivals: ["Masik Shivaratri"] },
    ],
  },
  {
    month: "October",
    Festival: [
      { date: 2, festivals: ["Ashwin Amavasya"] },
      { date: 3, festivals: ["Sharad Navratri, Ghatasthapana"] },
      { date: 9, festivals: ["Kalparambha "] },
      { date: 10, festivals: ["Navpatrika Puja"] },
      { date: 11, festivals: ["Durga Maha Navami Puja, Durga Puja Ashtami"] },
      { date: 12, festivals: ["Dussehra, Sharad Navratri Parana"] },
      { date: 13, festivals: ["Durga Visarjan "] },
      { date: 14, festivals: ["Papankusha Ekadashi"] },
      { date: 15, festivals: ["Pradosh Vrat (S)"] },
      { date: 17, festivals: ["Ashwin Purnima Vrat, Tula Sankranti"] },
      { date: 20, festivals: ["Sankashti Chaturthi, Karva Chauth"] },
      { date: 28, festivals: ["Rama Ekadashi"] },
      { date: 29, festivals: ["Dhanteras, Pradosh Vrat (K)"] },
      { date: 30, festivals: ["Masik Shivaratri"] },
      { date: 31, festivals: ["Narak Chaturdashi "] },
    ],
  },
  {
    month: "November",
    Festival: [
      { date: 1, festivals: ["Diwali, Kartik Amavasya"] },
      { date: 2, festivals: ["Govardhan Puja "] },
      { date: 3, festivals: ["Bhai Dooj"] },
      { date: 7, festivals: ["Chhath Puja"] },
      { date: 12, festivals: ["Devutthana Ekadashi"] },
      { date: 13, festivals: ["Pradosh Vrat (S)"] },
      { date: 15, festivals: ["Kartik Purnima Vrat"] },
      { date: 16, festivals: ["Vrischika Sankranti"] },
      { date: 18, festivals: ["Sankashti Chaturthi"] },
      { date: 26, festivals: ["Utpanna Ekadashi"] },
      { date: 28, festivals: ["Pradosh Vrat (K)"] },
      { date: 29, festivals: ["Masik Shivaratri"] },
    ],
  },
  {
    month: "December",
    Festival: [
      { date: 1, festivals: ["Margashirsha Amavasya"] },
      { date: 11, festivals: ["Mokshada Ekadashi"] },
      { date: 13, festivals: ["Pradosh Vrat (S)"] },
      { date: 15, festivals: ["Dhanu Sankranti, Margashirsha Purnima Vrat"] },
      { date: 18, festivals: ["Sankashti Chaturthi"] },
      { date: 26, festivals: ["Saphala Ekadashi"] },
      { date: 28, festivals: ["Pradosh Vrat (K)"] },
      { date: 29, festivals: ["Masik Shivaratri"] },
      { date: 30, festivals: ["Paush Amavasya"] },
    ],
  },
];

export const Tarot = {
  career: [
    {
      name: "The Fool",
      number: 0,
      arcana: "Major",
      prediction:
        "In your career, embrace new opportunities and take bold risks. Trust in your instincts and be open to unconventional paths. The Fool signifies the beginning of a new journey, where you have the freedom to explore uncharted territories and pursue your passions with enthusiasm and optimism.",
    },
    {
      name: "The Magician",
      number: 1,
      arcana: "Major",
      prediction:
        "You have the skills and resources to achieve success in your career. Use your creativity and confidence to manifest your goals. The Magician represents your ability to take action and make things happen, utilizing your talents and resources to bring your aspirations to life.",
    },
    {
      name: "The High Priestess",
      number: 2,
      arcana: "Major",
      prediction:
        "Trust your intuition in your career endeavors. Seek guidance from your inner wisdom and let it guide you towards the right decisions. The High Priestess symbolizes the power of intuition and inner knowledge, urging you to listen to your inner voice and trust in your instincts as you navigate your professional path.",
    },
    {
      name: "The Empress",
      number: 3,
      arcana: "Major",
      prediction:
        "Your career is about to enter a period of growth and abundance. Embrace your nurturing and creative side to foster success. The Empress signifies fertility and abundance, suggesting that your career endeavors will flourish and bear fruit. Embrace your creativity and tap into your nurturing instincts to create a thriving and fulfilling career.",
    },
    {
      name: "The Emperor",
      number: 4,
      arcana: "Major",
      prediction:
        "Establish authority and structure in your career. Take on leadership roles and embrace responsibility to achieve your ambitions. The Emperor represents strength, stability, and leadership, urging you to take charge of your career path. Embrace structure and discipline to create a solid foundation for your professional aspirations.",
    },
    {
      name: "The Hierophant",
      number: 5,
      arcana: "Major",
      prediction:
        "Tradition and wisdom will guide you in your career path. Seek mentorship and adhere to established principles to achieve your goals. The Hierophant symbolizes tradition and guidance, suggesting that you may benefit from seeking advice from experienced mentors or adhering to established practices in your career. Embrace wisdom and tradition as you navigate your professional journey.",
    },
    {
      name: "The Lovers",
      number: 6,
      arcana: "Major",
      prediction:
        "In your career, expect important decisions and choices to arise. Consider partnerships and collaborations carefully as they may lead to success. The Lovers card signifies choices and relationships, suggesting that your career path may involve important decisions regarding partnerships or collaborations. Consider your options carefully and choose paths that align with your values and aspirations.",
    },
    {
      name: "The Chariot",
      number: 7,
      arcana: "Major",
      prediction:
        "Drive and determination will propel you forward in your career. Focus on your goals and overcome obstacles to achieve victory. The Chariot represents determination and willpower, urging you to stay focused on your career objectives and push through obstacles with confidence. Harness your inner strength and drive to achieve success in your professional endeavors.",
    },
    {
      name: "Strength",
      number: 8,
      arcana: "Major",
      prediction:
        "Your inner strength and resilience will help you overcome challenges in your career. Trust in your abilities and persevere through difficulties. The Strength card signifies courage and resilience, reminding you of the inner strength and fortitude you possess. Trust in yourself and your capabilities to overcome challenges and achieve your career goals.",
    },
    {
      name: "The Hermit",
      number: 9,
      arcana: "Major",
      prediction:
        "Take time for introspection and reflection in your career. Solitude may lead to valuable insights that will guide you towards your true path. The Hermit symbolizes introspection and wisdom, suggesting that you may benefit from taking a step back to reflect on your career journey. Embrace solitude and self-reflection to gain clarity and insight into your professional aspirations.",
    },
    {
      name: "Wheel of Fortune",
      number: 10,
      arcana: "Major",
      prediction:
        "Your career is about to enter a period of change and transition. Embrace the opportunities that come your way and be prepared to adapt. The Wheel of Fortune represents cycles and change, suggesting that your career path may undergo significant shifts or transitions. Embrace uncertainty and remain flexible as you navigate new opportunities and challenges.",
    },
    {
      name: "Justice",
      number: 11,
      arcana: "Major",
      prediction:
        "Fairness and balance will lead to success in your career. Make decisions with integrity and consider the consequences of your actions. Justice symbolizes fairness and accountability, reminding you to approach your career with honesty and integrity. Consider the impact of your decisions on others and strive for balance and fairness in all your professional endeavors.",
    },
    {
      name: "The Hanged Man",
      number: 12,
      arcana: "Major",
      prediction:
        "Sacrifices may be required to progress in your career. Be willing to let go of old ways of thinking and embrace new perspectives. The Hanged Man represents surrender and sacrifice, suggesting that you may need to let go of certain beliefs or attitudes that no longer serve your career goals. Embrace change and open yourself up to new possibilities and perspectives.",
    },
    {
      name: "Death",
      number: 13,
      arcana: "Major",
      prediction:
        "Transformation is on the horizon in your career. Embrace endings as opportunities for new beginnings and growth. The Death card signifies endings and new beginnings, suggesting that your career may undergo a period of profound transformation. Embrace change and let go of old patterns or situations that no longer serve your professional growth.",
    },
    {
      name: "Temperance",
      number: 14,
      arcana: "Major",
      prediction:
        "Balance and moderation will bring harmony to your career. Avoid extremes and strive for a blend of efficiency and creativity. Temperance represents balance and moderation, urging you to find harmony in your career pursuits. Avoid overworking or burning out, and strive for a healthy balance between your professional and personal life.",
    },
    {
      name: "The Devil",
      number: 15,
      arcana: "Major",
      prediction:
        "Beware of temptation and unhealthy attachments in your career. Break free from toxic patterns that may hinder your progress. The Devil represents bondage and limitation, warning you against succumbing to negative influences or unhealthy attachments in your career. Break free from toxic patterns and embrace freedom and empowerment in your professional pursuits.",
    },
    {
      name: "The Tower",
      number: 16,
      arcana: "Major",
      prediction:
        "Your career may experience sudden upheaval or change. Embrace disruption as an opportunity to rebuild and innovate. The Tower represents upheaval and transformation, suggesting that your career path may undergo sudden and unexpected changes. Embrace the chaos as anopportunity to rebuild and innovate, even if it feels challenging at first. Use this period of disruption to reassess your goals and priorities, and embrace new opportunities that arise as a result of change.",
    },
    {
      name: "The Star",
      number: 17,
      arcana: "Major",
      prediction:
        "Hope and inspiration will guide you towards success in your career. Trust in your dreams and aspirations, and pursue your goals with confidence. The Star card signifies hope and optimism, suggesting that brighter days are ahead in your career. Trust in your inner guidance and believe in the power of your dreams to lead you towards success.",
    },
    {
      name: "The Moon",
      number: 18,
      arcana: "Major",
      prediction:
        "Navigate through uncertainty with intuition and imagination in your career. Embrace ambiguity and trust your inner guidance. The Moon represents mystery and intuition, urging you to trust your instincts as you navigate through uncertain times in your career. Embrace the unknown and let your intuition be your guide towards success.",
    },
    {
      name: "The Sun",
      number: 19,
      arcana: "Major",
      prediction:
        "Radiant success and fulfillment await you in your career. Embrace positivity and confidence as you pursue your ambitions. The Sun card signifies joy and success in your career, suggesting that you are on the path to achieving your goals. Embrace optimism and let your light shine brightly as you pursue your professional aspirations.",
    },
    {
      name: "Judgement",
      number: 20,
      arcana: "Major",
      prediction:
        "A time of reckoning and evaluation is approaching in your career. Reflect on past decisions and embrace a fresh start. Judgement signifies a period of self-reflection and evaluation in your career. Take stock of your achievements and shortcomings, and be open to making necessary changes to align with your true purpose and aspirations.",
    },
    {
      name: "The World",
      number: 21,
      arcana: "Major",
      prediction:
        "Completion and fulfillment are on the horizon in your career. Embrace your achievements and celebrate your successes. The World card signifies the culmination of your efforts and the attainment of your goals in your career. Celebrate your achievements and embrace the sense of wholeness and fulfillment that comes with reaching the end of a significant chapter in your professional journey.",
    },
  ],

  love: [
    {
      name: "The Fool",
      number: 0,
      arcana: "Major",
      prediction:
        "In matters of love, embrace new beginnings and take risks. Trust your instincts and be open to new romantic opportunities. The Fool signifies the start of a new romantic journey, where you're free to explore uncharted territory and pursue your heart's desires with enthusiasm and optimism.",
    },
    {
      name: "The Magician",
      number: 1,
      arcana: "Major",
      prediction:
        "You have the ability to manifest love in your life. Use your charm and confidence to attract a romantic partner. The Magician represents your power to make things happen in matters of love. Utilize your skills and resources to create the loving relationship you desire.",
    },
    {
      name: "The High Priestess",
      number: 2,
      arcana: "Major",
      prediction:
        "Trust your intuition in matters of the heart. Listen to your inner wisdom and let it guide you towards the right romantic decisions. The High Priestess symbolizes the importance of intuition and inner knowing in matters of love. Pay attention to your instincts and trust in the deeper truths of your heart.",
    },
    {
      name: "The Empress",
      number: 3,
      arcana: "Major",
      prediction:
        "Your love life is about to enter a period of growth and abundance. Embrace your nurturing and loving nature to cultivate a fulfilling relationship. The Empress represents fertility and nurturing energy in matters of love. Open yourself up to love and create a loving and supportive environment for yourself and your partner.",
    },
    {
      name: "The Emperor",
      number: 4,
      arcana: "Major",
      prediction:
        "Establish stability and security in your romantic relationships. Take on a leadership role and provide a strong foundation for your partnership. The Emperor represents structure and authority in matters of love. Take charge of your love life and create a relationship built on trust, respect, and stability.",
    },
    {
      name: "The Hierophant",
      number: 5,
      arcana: "Major",
      prediction:
        "Tradition and spiritual guidance will play a significant role in your love life. Seek wisdom from trusted sources and honor the values of your relationship. The Hierophant symbolizes the importance of tradition and spiritual connection in matters of love. Seek guidance from mentors or spiritual leaders to strengthen your relationship.",
    },
    {
      name: "The Lovers",
      number: 6,
      arcana: "Major",
      prediction:
        "Important decisions about love and relationships are on the horizon. Choose your romantic partners and commitments wisely. The Lovers card signifies choices and partnerships in matters of love. Consider the long-term implications of your romantic decisions and follow your heart's true desires.",
    },
    {
      name: "The Chariot",
      number: 7,
      arcana: "Major",
      prediction:
        "Drive and determination will lead you to romantic success. Stay focused on your romantic goals and overcome obstacles with confidence. The Chariot represents determination and willpower in matters of love. Channel your passion and energy into your romantic pursuits and navigate any challenges that arise with strength and determination.",
    },
    {
      name: "Strength",
      number: 8,
      arcana: "Major",
      prediction:
        "Your inner strength and courage will help you overcome obstacles in your love life. Trust in yourself and your ability to overcome any challenges that come your way. The Strength card represents inner fortitude and resilience in matters of love. Face any challenges in your relationship with courage and compassion, and you will emerge stronger than ever.",
    },
    {
      name: "The Hermit",
      number: 9,
      arcana: "Major",
      prediction:
        "Take time for introspection and self-discovery in matters of love. Reflect on your needs and desires to find clarity in your romantic life. The Hermit suggests the importance of solitude and reflection in matters of love. Take a step back from your relationship to reconnect with yourself and gain valuable insights into your romantic aspirations.",
    },
    {
      name: "Wheel of Fortune",
      number: 10,
      arcana: "Major",
      prediction:
        "Your love life is about to undergo a significant change or transition. Embrace the opportunities that arise and trust in the universe's plan for your romantic journey. The Wheel of Fortune represents cycles and changes in matters of love. Be open to new experiences and possibilities, and embrace the twists and turns of your romantic path.",
    },
    {
      name: "Justice",
      number: 11,
      arcana: "Major",
      prediction:
        "Fairness and balance are essential in your romantic relationships. Make decisions with integrity and consider the needs of both yourself and your partner. Justice signifies fairness and accountability in matters of love. Ensure that your relationships are built on honesty, trust, and mutual respect, and strive for balance and harmony in your romantic partnerships.",
    },
    {
      name: "The Hanged Man",
      number: 12,
      arcana: "Major",
      prediction:
        "Sacrifices may be necessary for your romantic growth. Let go of old patterns or attachments that no longer serve your highest good. The Hanged Man represents surrender and release in matters of love. Release any attachments or expectations that may be holding you back from experiencing true love and fulfillment in your romantic life.",
    },
    {
      name: "Death",
      number: 13,
      arcana: "Major",
      prediction:
        "Transformation is on the horizon in your love life. Embrace endings as opportunities for new beginnings and personal growth. Death signifies endings and new beginnings in matters of love. Let go of past relationships or outdated beliefs about love, and embrace the opportunity for renewal and transformation in your romantic journey.",
    },
    {
      name: "Temperance",
      number: 14,
      arcana: "Major",
      prediction:
        "Balance and moderation are key to maintaining harmony in your romantic relationships. Avoid extremes and strive for a blend of love, patience, and understanding. Temperance represents balance and moderation in matters of love. Practice patience and compromise in your relationships, and seek harmony and peace in your romantic partnerships.",
    },
    {
      name: "The Devil",
      number: 15,
      arcana: "Major",
      prediction:
        "Beware of temptation and unhealthy attachments in your love life. Break free from toxic patterns and embrace freedom and empowerment in your romantic relationships. The Devil symbolizes bondage and limitation in matters of love. Release yourself from any unhealthy attachments or toxic relationships that may be hindering your romantic growth.",
    },
    {
      name: "The Tower",
      number: 16,
      arcana: "Major",
      prediction:
        "Your love life may experience sudden upheaval or change. Embrace the opportunity for growth and transformation that comes with disruption. The Tower represents sudden change and revelation in matters of love. Embrace the chaos as an opportunity to rebuild and reinvent your romantic relationships, and trust that new beginnings are on the horizon.",
    },
    {
      name: "The Star",
      number: 17,
      arcana: "Major",
      prediction:
        "Hope and healing are available to you in matters of love. Trust in the universe's plan and let your love shine brightly. The Star signifies hope and inspiration in matters of love. Trust in the power of love to heal past wounds and illuminate your romantic path, and believe in the possibility of a bright and beautiful future filled with love and happiness.",
    },
    {
      name: "The Moon",
      number: 18,
      arcana: "Major",
      prediction:
        "Navigate through uncertainty with intuition and imagination in matters of love. Embrace the mysteries of love and trust in your inner guidance. The Moon represents intuition and illusion in matters of love. Trust your instincts and intuition to navigate through the uncertainties of your romantic journey, and let your imagination lead you towards the love and fulfillment you seek.",
    },
    {
      name: "The Sun",
      number: 19,
      arcana: "Major",
      prediction:
        "Radiant happiness and fulfillment await you in love! Bask in the warmth of love and let your heart overflow with joy. The Sun card represents joy and happiness in matters of love. Embrace the love and positivity that surrounds you, and allow yourself to experience the fullness of romantic happiness and fulfillment.",
    },
    {
      name: "Judgement",
      number: 20,
      arcana: "Major",
      prediction:
        "A moment of truth and self-evaluation is approaching in your love life. Embrace forgiveness and let go of past hurts to create a fresh start in your relationships. Judgement signifies a time of reckoning and self-reflection in matters of love. Reflect on past experiences and relationships, and embrace forgiveness and acceptance as you move forward with clarity and purpose in your romantic journey.",
    },
    {
      name: "The World",
      number: 21,
      arcana: "Major",
      prediction:
        "Completion and fulfillment are on the horizon in your love life. Embrace your achievements and celebrate the love and connection you share with your partner. The World card signifies the ultimate fulfillment and completion in matters of love. Celebrate your love and the journey you've shared together, and embrace the unity and wholeness of your romantic relationship.",
    },
  ],
  daily: [
    {
      number: 0,
      name: "The Fool",
      prediction:
        "Today marks the beginning of a new journey for you. Embrace the sense of adventure and spontaneity that comes with stepping into the unknown. Trust in the universe to guide you as you take the first steps toward your dreams. Keep an open mind and a light heart, for exciting opportunities await around every corner.",
    },
    {
      number: 1,
      name: "The Magician",
      prediction:
        "You possess all the tools you need to manifest your desires today. Tap into your creativity and resourcefulness to turn your dreams into reality. With focus and determination, you can achieve anything you set your mind to. Believe in yourself and your abilities, for you have the power to shape your destiny.",
    },
    {
      number: 2,
      name: "The High Priestess",
      prediction:
        "Listen closely to your intuition today, for it holds the key to unlocking hidden truths and insights. Trust in the wisdom of your inner voice as you navigate the mysteries of the day. Pay attention to the signs and synchronicities that guide you on your path. By tuning into your intuition, you can find clarity and guidance in any situation.",
    },
    {
      number: 3,
      name: "The Empress",
      prediction:
        "Today is a day to nurture yourself and those around you. Embrace the beauty and abundance of the world, and allow yourself to luxuriate in life's simple pleasures. Focus on cultivating loving relationships and creative endeavors. Trust in the natural flow of life to provide for your needs and desires.",
    },
    {
      number: 4,
      name: "The Emperor",
      prediction:
        "Take charge of your day with authority and confidence. Set clear boundaries and establish order in your life. By taking control of your circumstances, you can achieve success and stability. Trust in your ability to lead and make decisions that serve your highest good.",
    },
    {
      number: 5,
      name: "The Hierophant",
      prediction:
        "Today, embrace tradition and seek guidance from trusted sources. Honor the wisdom of those who have come before you and adhere to established principles. Seek out mentors or advisors who can offer insight and support on your journey. Trust in the power of tradition to provide stability and direction in uncertain times.",
    },
    {
      number: 6,
      name: "The Lovers",
      prediction:
        "Love is in the air today, bringing harmony and connection to your relationships. Embrace the bonds of love and friendship that surround you. Whether you're spending time with a romantic partner or cherished friends, focus on nurturing those connections and expressing your appreciation for the people in your life.",
    },
    {
      number: 7,
      name: "The Chariot",
      prediction:
        "Today, you're unstoppable. With focus and determination, you can overcome any obstacle that stands in your way. Trust in your abilities and stay the course, knowing that victory is within reach. Embrace the challenges of the day with confidence and resilience, and you'll emerge stronger than ever before.",
    },
    {
      number: 8,
      name: "Strength",
      prediction:
        "You possess the inner strength and courage to face whatever challenges come your way today. Draw upon your resilience and determination to overcome any obstacles you encounter. Remember that true strength lies in compassion and gentleness, so be kind to yourself as you navigate the ups and downs of the day.",
    },
    {
      number: 9,
      name: "The Hermit",
      prediction:
        "Take time for solitude and introspection today. Retreat from the busyness of the world and turn inward to seek wisdom and guidance. Trust in your own inner light to illuminate the path forward. Through meditation and reflection, you can find the answers you seek and gain a deeper understanding of yourself and your journey.",
    },
    {
      number: 10,
      name: "Wheel of Fortune",
      prediction:
        "Embrace the cycles of change and transformation that are at work in your life today. Trust in the divine timing of the universe and surrender to the flow of destiny. Know that each turn of the wheel brings new opportunities for growth and evolution. Embrace the unknown with courage and curiosity, for fortune favors the bold.",
    },
    {
      number: 11,
      name: "Justice",
      prediction:
        "Seek balance and fairness in all your dealings today. Act with integrity and honesty, knowing that your actions have consequences. Trust in the universal laws of cause and effect to bring about justice and harmony. Make decisions with clarity and objectivity, and strive to create a world where everyone is treated with dignity and respect.",
    },
    {
      number: 12,
      name: "The Hanged Man",
      prediction:
        "Surrender to the ebb and flow of life today, and embrace the wisdom that comes with letting go. Release yourself from attachments and expectations that no longer serve you. Trust in the process of surrender and allow yourself to be guided by divine wisdom. Through acceptance and surrender, you can find peace and enlightenment.",
    },
    {
      number: 13,
      name: "Death",
      prediction:
        "Embrace the transformative power of change today, for it brings renewal and rebirth. Let go of old patterns and beliefs that no longer serve your highest good. Trust in the process of death and rebirth as you shed the old and make way for the new. Embrace the unknown with courage and grace, knowing that endings are just beginnings in disguise.",
    },
    {
      number: 14,
      name: "Temperance",
      prediction:
        "Find balance and harmony in all aspects of your life today. Blend opposing forces with grace and moderation, finding the middle path that leads to peace and fulfillment. Trust in the process of alchemy to transform challenges into opportunities for growth and healing. Embrace the journey of self-discovery and allow yourself to be guided by divine wisdom.",
    },
    {
      number: 15,
      name: "The Devil",
      prediction:
        "Confront your shadow side with courage and honesty today. Acknowledge the fears and desires that hold you captive, and release yourself from their grip. Trust in your ability to break free from self-imposed limitations and reclaim your power. Embrace the journey of liberation and liberation, knowing that true freedom comes from within.",
    },
    {
      number: 16,
      name: "The Tower",
      prediction:
        "Prepare for upheaval and change today, as the Tower brings sudden and unexpected events into your life. Embrace the destruction of old structures and beliefs that no longer serve your highest good. Trust in the process of transformation and know that out of chaos comes new beginnings. Embrace the opportunity for growth and evolution, and welcome the winds of change with open arms.",
    },
    {
      number: 17,
      name: "The Star",
      prediction:
        "Today, find hope and inspiration in the darkest of times. Allow the light of the Star to guide you through the darkness, illuminating the path to healing and renewal. Trust in the universe to provide for your needs and lead you toward success. Embrace the journey of self-discovery and allow yourself to shine brightly, knowing that you are a beacon of hope and inspiration to others.",
    },
    {
      number: 18,
      name: "The Moon",
      prediction:
        "Explore the depths of your subconscious mind today, as the Moon shines its light on hidden truths and mysteries. Trust in your intuition to guide you through the darkness, illuminating the path forward. Embrace the unknown with courage and curiosity, knowing that the journey of self-discovery leads to enlightenment. Allow yourself to be guided by the wisdom of the unconscious mind, and trust in the process of transformation.",
    },
    {
      number: 19,
      name: "The Sun",
      prediction:
        "Bask in the warm glow of the Sun today, as it illuminates your path with joy and vitality. Embrace the energy of optimism and enthusiasm, knowing that brighter days lie ahead. Trust in the universe to provide for your needs and guide you toward success. Embrace the journey of self-discovery and allow yourself to shine brightly, for you are a radiant beacon of light and love.",
    },
    {
      number: 20,
      name: "Judgement",
      prediction:
        "Today, heed the call to awaken to your true purpose and potential. Embrace the process of rebirth and transformation, knowing that you are being called to higher levels of consciousness. Trust in the divine guidance that leads you toward your highest destiny. Embrace the journey of self-discovery and allow yourself to be reborn anew, for you are being called to fulfill your soul's purpose.",
    },
    {
      number: 21,
      name: "The World",
      prediction:
        "Celebrate the completion of a cycle and the beginning of a new chapter in your life today. Embrace the sense of fulfillment and accomplishment that comes with reaching your goals. Trust in the universal rhythms of creation and know that you are a co-creator of your own destiny. Embrace the journey of self-discovery and allow yourself to expand into the boundless possibilities that await you.",
    },
  ],
  weekly: [
    {
      number: 0,
      name: "The Fool",
      prediction:
        "This week, you're at the beginning of a brand new journey. It's like standing on the edge of a cliff, ready to leap into the unknown. Embrace the feeling of excitement and anticipation that comes with starting something fresh. While it may seem daunting, trust that the universe has your back and is guiding you on this adventure. Stay open-minded, be spontaneous, and don't be afraid to take risks. The Fool encourages you to embrace the journey with a sense of wonder and optimism.",
    },
    {
      number: 1,
      name: "The Magician",
      prediction:
        "As the Magician, you have all the tools you need to manifest your desires this week. You possess a unique blend of skills, talents, and resources that make you capable of turning your dreams into reality. This week, focus on tapping into your inner power and taking decisive action towards your goals. Whether it's starting a new project, pursuing a passion, or making a bold move, trust in your abilities to make things happen. Remember, you are the master of your own destiny.",
    },
    {
      number: 2,
      name: "The High Priestess",
      prediction:
        "This week, the High Priestess urges you to listen to your intuition and trust your inner voice. You may find yourself drawn to quiet reflection and introspection as you seek deeper understanding and insight. Pay attention to your dreams, hunches, and gut feelings, as they are messages from your subconscious mind. Trust in your intuition to guide you through any challenges or uncertainties that arise. By tuning into your inner wisdom, you can gain clarity and make decisions that align with your highest good.",
    },
    {
      number: 3,
      name: "The Empress",
      prediction:
        "The Empress brings a message of nurturing and abundance for you this week. It's a time to focus on self-care, nurturing your relationships, and connecting with the beauty of the natural world. Embrace your feminine energy and celebrate the abundance that surrounds you. Whether it's through creativity, fertility, or simply enjoying life's pleasures, allow yourself to luxuriate in the richness of your experiences. Trust that you are supported and loved by the universe.",
    },
    {
      number: 4,
      name: "The Emperor",
      prediction:
        "Take charge of your week with authority and confidence, just like the Emperor. Set clear boundaries, make decisions with conviction, and take control of your circumstances. This is a time to assert yourself and assert your authority in any area of your life where you may have been feeling out of control. Trust in your ability to lead and govern with wisdom and strength. Remember, you have the power to shape your destiny.",
    },
    {
      number: 5,
      name: "The Hierophant",
      prediction:
        "The Hierophant encourages you to seek guidance from tradition and authority figures this week. It's a time to honor the wisdom of those who have come before you and adhere to established principles. Whether it's seeking advice from a mentor, studying ancient teachings, or participating in rituals and ceremonies, trust in the wisdom of tradition to provide guidance and support. Remember, there is much to be gained from honoring the past and respecting the wisdom of those who have walked the path before you.",
    },
    {
      number: 6,
      name: "The Lovers",
      prediction:
        "Love and connection are at the forefront for you this week, just like the Lovers card. Whether you're in a romantic relationship or not, this is a time to focus on deepening your connections with others. Make time for meaningful conversations, express your feelings openly, and nurture the bonds that bring you joy and fulfillment. Trust that love is all around you, and allow yourself to give and receive it freely. Remember, the greatest power we have is the power to love.",
    },
    {
      number: 7,
      name: "The Chariot",
      prediction:
        "This week, you're unstoppable, like the Chariot charging full speed ahead. You have a clear sense of purpose and the determination to overcome any obstacles that stand in your way. Trust in your abilities, stay focused on your goals, and don't let anything or anyone derail you from your path. Whether you're pursuing a personal ambition or facing a challenge head-on, know that victory is within reach. Embrace the journey with confidence and resilience, and you'll emerge stronger than ever before.",
    },
    {
      number: 8,
      name: "Strength",
      prediction:
        "You possess an inner strength that will help you overcome any challenges you encounter this week. Like the figure in the Strength card, you have the power to tame the lion of adversity with grace and compassion. Trust in your resilience, courage, and ability to endure whatever comes your way. Remember, true strength comes from within, and you have everything you need to face whatever challenges lie ahead. Stay centered, stay strong, and trust in yourself.",
    },
    {
      number: 9,
      name: "The Hermit",
      prediction:
        "This week, the Hermit invites you to take some time for introspection and solitude. Turn inward and reflect on your thoughts, feelings, and experiences. Trust that the answers you seek are within you, waiting to be discovered. Take advantage of this opportunity to recharge your batteries, gain clarity, and reconnect with your inner wisdom. Remember, solitude is not isolation but an opportunity for self-discovery and growth. Embrace the silence and allow yourself to be guided by your inner light.",
    },
    {
      number: 10,
      name: "Wheel of Fortune",
      prediction:
        "The Wheel of Fortune reminds you that life is full of cycles and changes. This week, expect the unexpected as the wheel turns and brings new opportunities and challenges your way. Embrace the ups and downs with equanimity, knowing that every turn of the wheel brings with it the potential for growth and evolution. Trust in the universe's timing and be open to the possibilities that present themselves. Remember, change is inevitable, but how you respond to it is up to you.",
    },
    {
      number: 11,
      name: "Justice",
      prediction:
        "Seek balance and fairness in your actions and decisions this week, just like the Justice card. Make choices with integrity and consider the consequences of your actions carefully. Trust in the principles of justice and karma to guide you. Remember, every action has an equal and opposite reaction, so strive to act with honesty, fairness, and compassion. Trust that by doing the right thing, you'll create positive outcomes for yourself and others.",
    },
    {
      number: 12,
      name: "The Hanged Man",
      prediction:
        "The Hanged Man encourages you to surrender and let go of your attachments and expectations this week. Release yourself from the need to control everything and trust in the process of surrender. Sometimes, the greatest insights and revelations come when we stop resisting and allow ourselves to be still. Embrace this period of suspension as an opportunity for introspection, growth, and enlightenment. Trust that by surrendering to the flow of life, you'll find peace and clarity.",
    },
    {
      number: 13,
      name: "Death",
      prediction:
        "Embrace the transformative power of change this week, just like the Death card. Let go of what no longer serves you and make room for new beginnings. Trust that endings are just as important as beginnings, as they pave the way for growth and evolution. Embrace the process of transformation, even if it feels uncomfortable or scary. Remember, every death is followed by a rebirth, and every ending is followed by a new beginning.",
    },
    {
      number: 14,
      name: "Temperance",
      prediction:
        "This week, find balance and harmony in all areas of your life, just like the figure in the Temperance card. Blend opposing forces with grace and moderation, finding the middle path that leads to peace and fulfillment. Trust in the process of alchemy to transform challenges into opportunities for growth and healing. Embrace the journey of self-discovery and allow yourself to be guided by divine wisdom. Remember, true balance comes from within, so stay centered and trust in the natural flow of life.",
    },
    {
      number: 15,
      name: "The Devil",
      prediction:
        "Confront your shadow side with courage and honesty this week, just like the figure in the Devil card. Acknowledge the fears, desires, and attachments that hold you captive, and take steps to break free from their grip. Trust in your ability to overcome self-imposed limitations and reclaim your power. Embrace the journey of liberation and transformation, knowing that true freedom comes from within. Remember, you have the strength and resilience to overcome any obstacle.",
    },
    {
      number: 16,
      name: "The Tower",
      prediction:
        "Prepare for sudden changes and upheaval this week, just like the Tower card. While it may feel unsettling or uncomfortable at first, trust that these changes are clearing the way for something new and better to emerge. Embrace the opportunity for growth and transformation, even if it feels challenging. Remember, out of chaos comes new beginnings, and sometimes, the greatest insights come from the greatest upheavals. Trust in the process and know that you are supported by the universe.",
    },
    {
      number: 17,
      name: "The Star",
      prediction:
        "Find hope and inspiration in the midst of darkness this week, just like the figure in the Star card. Trust that brighter days are ahead and allow your inner light to shine. Embrace the journey of self-discovery and renewal as you navigate through challenges and obstacles. Remember, the darkest nights produce the brightest stars, and every setback is an opportunity for growth. Trust in the universe's plan for you and know that you are guided and supported every step of the way.",
    },
    {
      number: 18,
      name: "The Moon",
      prediction:
        "Explore the depths of your subconscious mind this week, just like the figure in the Moon card. Pay attention to your dreams, intuitions, and hidden fears as they hold valuable insights and messages. Trust in your inner guidance to navigate through uncertainty and confusion. Embrace the journey of self-discovery and allow yourself to be guided by the wisdom of your subconscious mind. Remember, sometimes the greatest truths are found in the darkest corners of our minds.",
    },
    {
      number: 19,
      name: "The Sun",
      prediction:
        "Bask in the warmth and positivity of the Sun this week, just like the child in the Sun card. Embrace joy, optimism, and vitality in all areas of your life. Trust that brighter days are ahead and allow yourself to shine brightly. Celebrate your successes, express gratitude for your blessings, and share your light with others. Remember, you are a radiant being of love and light, and the world is brighter because of you.",
    },
    {
      number: 20,
      name: "Judgement",
      prediction:
        "This week, heed the call to awaken to your true purpose and potential, just like the figures in the Judgement card. Embrace the opportunity for rebirth and transformation as you shed old patterns and beliefs that no longer serve you. Trust in divine guidance to lead you toward your highest destiny. Embrace the journey of self-discovery and allow yourself to be reborn anew. Remember, you are being called to fulfill your soul's purpose, and the universe is supporting you every step of the way.",
    },
    {
      number: 21,
      name: "The World",
      prediction:
        "Celebrate your achievements and milestones this week, just like the figure in the World card. Embrace the sense of fulfillment and completion that comes with reaching your goals. Trust in your ability to create the life you desire and know that you are a co-creator of your own destiny. Embrace the journey of self-discovery and allow yourself to expand into the boundless possibilities that await you. Remember, the world is yours to explore and conquer.",
    },
  ],
};
