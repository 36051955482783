export const initialState = {
  blogs: [],
  isadmin: true,
  subscribe: false,
  error: {
    error: false,
    errormessage: "",
  },
};

export const reducer = (state: any, action: { type: string; payload: any }) => {
  switch (action.type) {
    case "SetBlog":
      return {
        ...state,
        blogs: action.payload,
      };
    case "isadmin":
      return {
        ...state,
        isadmin: true,
      };
    case "Setsubscibe":
      return {
        ...state,
        subscribe: action.payload,
      };
    case "error":
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
