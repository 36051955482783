import logo from "./logo.svg";
import "./App.css";
import RouteConfig from "./Routes/Routes.js";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { _getPageData } from "./Services/OtherManager.ts";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    _getPageData(dispatch);
  }, []);
  return <RouteConfig />;
}

export default App;
