import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  AdminBlogEdit,
  AdminBlogInsertion,
  AdminBlogs,
  AdminHome,
  BlogDetail,
  Blogs,
  Contactus,
  Dashboard,
  Festivals,
  Home,
  Kundali,
  OrderConfirmPage,
  PrivacyPolicy,
  RefundPolicy,
  Tarot,
  Terms,
  UserKundali,
} from "./Links.ts";
import { Suspense } from "react";
import BlogInsertion from "../Pages/Admin/dashboard/BlogInsertion.jsx";

function Routers() {
  return (
    <Router>
      <Routes>
        <Route
          path="/in/hq/Admin/dashboard/edit/:id"
          element={
            <Suspense>
              <div className="App">
                <AdminBlogEdit />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/in/hq/Admin/dashboard/blogs/insert"
          element={
            <Suspense>
              <div className="App">
                <AdminBlogInsertion />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/in/hq/Admin/dashboard/blogs"
          element={
            <Suspense>
              <div className="App">
                <AdminBlogs />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/in/hq/Admin/dashboard/"
          element={
            <Suspense>
              <div className="App">
                <Dashboard />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/in/hq/Admin/dashboard/kundali"
          element={
            <Suspense>
              <div className="App">
                <UserKundali />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/in/hq/Admin"
          element={
            <Suspense>
              <div className="App">
                <AdminHome />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/in/refundpolicy"
          element={
            <Suspense>
              <RefundPolicy />
            </Suspense>
          }
        />
        <Route
          path="/in/privancypolicy"
          element={
            <Suspense>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/in/termsandcondition"
          element={
            <Suspense>
              <Terms />
            </Suspense>
          }
        />
        <Route
          path="/in/contactus"
          element={
            <Suspense>
              <Contactus />
            </Suspense>
          }
        />
        <Route
          path="/in/order/kundali"
          element={
            <Suspense>
              <Kundali />
            </Suspense>
          }
        />
        <Route
          path="/in/festivals"
          element={
            <Suspense>
              <Festivals />
            </Suspense>
          }
        />
        <Route
          path="/blog/:blogheading"
          element={
            <Suspense>
              <BlogDetail />
            </Suspense>
          }
        />
        <Route
          path="/blogs"
          element={
            <Suspense>
              <Blogs />
            </Suspense>
          }
        />
        <Route
          path="/:type/TarotCard"
          element={
            <Suspense>
              <Tarot />
            </Suspense>
          }
        />

        <Route
          path="/Payment/Confirm/:orderid"
          element={<OrderConfirmPage />}
        />
        <Route
          path="/"
          element={
            <Suspense>
              <Home />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default Routers;
