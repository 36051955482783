import { Dispatch } from "redux";
import { _apiGetCalls, _apiPostCalls } from "./ApiServices.ts";
import { load } from "@cashfreepayments/cashfree-js";

let isRequestPending = false;

export const _getGeoCode = (value: string) => {
  if (isRequestPending) {
    return Promise.reject("Request throttled");
  }

  return new Promise(async (resolve, reject) => {
    try {
      isRequestPending = true;
      const request = await _apiGetCalls(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          `${value}`
        )}&format=json`,
        ""
      );
      isRequestPending = false;
      return resolve(request);
    } catch (error) {
      isRequestPending = false;
      reject(error);
    }
  });
};

export const getValidTime = (dateTimeString: string) => {
  const dateTime = new Date(dateTimeString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    //   year: "numeric",
    month: "short",
    day: "2-digit",
  };

  // Format the date and time
  const formattedDateTime = dateTime.toLocaleString("en-US", options as any);
  return formattedDateTime;
};

export const IsAdmin = async () => {
  const token = localStorage.getItem("jwt");
  if (token) {
    try {
      const request = await _apiGetCalls("/Auth/isLoggedin.php", token);
      return { request };
    } catch (err) {
      return err;
    }
  } else {
    throw new Error("Not Authorize");
  }
};

export const ErrorHandler = (
  field: string | boolean | any,
  errormessage: string,
  dispatch: Dispatch
) => {
  if (!field) {
    dispatch({
      type: "error",
      payload: {
        error: true,
        errormessage: errormessage,
      },
    });
    setTimeout(() => {
      dispatch({
        type: "error",
        payload: {
          error: false,
          errormessage: errormessage,
        },
      });
    }, 3000);
    return false;
  }
  return true;
};

export const _getPageData = async (dispatch: any) => {
  try {
    const blogs: any = await _apiGetCalls("/blogs", "");
    dispatch({
      type: "SetBlog",
      payload: blogs.data,
    });
    return;
  } catch (err) {
    console.log(err);
  }
};

const createOrder = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const request: any = await _apiPostCalls("/user", data, "");
      resolve(request);
    } catch (err) {
      return reject();
    }
  });
};

export const _handlePayment = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const order: any = await createOrder(data);

      const options = {
        key: "rzp_live_RQzzWKaCrOClbT", // Enter the Key ID generated from the Dashboard
        amount: 199 * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "astrogyanam",
        description:
          "Explore Astrology's Insights into Personality, Relationships, and Life's Path",
        // image: "https://skillkart.app/favicon.ico",
        order_id: order.order, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          let formdata = new FormData();
          formdata.append("id", order.userId);
          formdata.append("payment_id", response.razorpay_payment_id);
          formdata.append("status", 1);
          await _apiPostCalls("/update/payment", formdata, "").then(() => {
            window.location.assign(`/Payment/Confirm/${order.order}`);
          });
        },
        prefill: {
          name: data.name,
          email: data.Email,
          contact: data.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.on("payment.failed", async function (response: any) {
        let formdata = new FormData();
        formdata.append("id", order.userId);
        formdata.append("payment_id", response.error.metadata.payment_id);
        formdata.append("status", 0);
        await _apiPostCalls("/update/payment", formdata, "");
      });
      rzp1.open();
    } catch (err) {
      console.log(err);
      return reject();
    }
  });
};

const initializeSDK = async () => {
  return new Promise(async (resolve, reject) => {
    let request = await load({
      mode: "production",
    });
    return resolve(request);
  });
};

export const _handleCashfreePayment = async (formdata: any, setLoading) => {
  try {
    const order: any = await createOrder(formdata);
    console.log(order);
    let cashfree: any = await initializeSDK();

    let checkoutOptions = {
      paymentSessionId: order.order.payment_session_id,
      redirectTarget: "_modal",
    };
    cashfree.checkout(checkoutOptions).then(async (result) => {
      console.log(result);
      if (result.error) {
        // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
        console.log(
          "User has closed the popup or there is some payment error, Check for Payment Status"
        );
        setLoading(false);
      }
      if (result.redirect) {
        // This will be true when the payment redirection page couldnt be opened in the same window
        // This is an exceptional case only when the page is opened inside an inAppBrowser
        // In this case the customer will be redirected to return url once payment is completed
        console.log("Payment will be redirected");
        setLoading(false);
      }
      if (result.paymentDetails) {
        // This will be called whenever the payment is completed irrespective of transaction status
        let updatedata = new FormData();
        updatedata.append("id", order.userId);
        updatedata.append("payment_id", order.order.payment_session_id);
        updatedata.append("username", formdata.get("name"));
        updatedata.append("email", formdata.get("email"));
        updatedata.append("status", 1);
        const data: any = await _apiPostCalls(
          "/update/payment",
          updatedata,
          ""
        );
        setLoading(false);
        window.location.assign(
          `/Payment/Confirm/${order.order.payment_session_id}`
        );
      }
    });
  } catch (err) {
    console.log(err);
  }
};
